import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="FAQ">
    <h2>Frequently Asked Questions</h2>
    <div className="clear-block">
      <div id="node-21" className="node">
        <div className="content clear-block">
          <ol>
            <li>I have a three year old and want to know if it is really possible to begin giving
              her/him piano lessons?
              <p>Yes it is. Most three year olds are able to learn the Animal Notes, find them on
                the Grand Staff, and then play them on the keyboard. With patient help and telling them that
                their hand is a magic spider that plays the piano, with each leg on a different key, they
                can learn to use correct hand position. The Animal Note Flash Cards help them learn the word
                clues and apply them to standard notes. Their interest span is short, it is better to work 5
                minutes or perhaps 10 minutes a day, 2 or 3 times a week, rather than longer lessons.</p>
            </li>
            <li>I have never had any piano lessons myself, would it be possible for me to use this
              method to teach my child?
              <p>The teaching materials in these books are written so anyone can teach them, even if
                they have never had any music lessons themselves. You need time to work with your child,
                enjoy sharing the learning experience, and practice patience with them as they sometimes
                need to go over material more than once to master it. Your efforts will be greatly rewarded
                as you watch your child learn and enjoy playing the piano.</p></li>
            <li>I am a grandparent and only see my grandchildren a couple of times a month, but
              I would love to use your method to teach them piano. Would this be enough to give them a
              decent start in music?
              <p>They will not progress as rapidly as a child who is able to have a lesson once a
                week, but they can progress and succeed. You might need to call them once in awhile and ask
                them if they have practiced what you assigned them. They will love this extra attention and
                it will keep them interested, as well as prepared for the next time you are together.</p>
            </li>
            <li>I have a 6 year old son. Is he too old to use this method?
              <p>No, your son is a perfect age to start teaching piano lessons with this method. In
                the last year our teaching studio has successfully used the method for beginners as old as
                14. We thought older students would think it was silly to learn music this way, but they
                found it interesting and easy. They loved the progress they were able to make. A couple have
                tried lessons before, but struggled learning standard music notation. They were thrilled
                when, after a few short months of working through the Animal Notes books, they were able to
                note read standard music notes without difficulty.</p></li>
            <li>I am 13; could I teach myself to play the piano with your method?
              <p>You most certainly can, as it is easy to understand and follow as you advance from
                one book to the next. Please make certain you go through “Note Reading”, “Timing”, at least one
                “Fun Song” book, and the first, and possible the second, “Moving On” book; and do get the
                “Theory” book to further help you understand the basic elements of music theory.</p></li>
            <li>Do you have anyone in my area teaching your method?
              <p>We do not have that information at this time. However, we would like to extend an
                invitation to any one who is teaching this method and wanting more students to let us know.
                We will make this information available on our website and try to link interested teachers
                with interested parents seeking their services.</p></li>
          </ol>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
